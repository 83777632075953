import { ContentColumn } from '../..'
import { HomeCareResidencyCostMix } from './HomeCareResidencyCostMix'
import { VolumeAgeGroup } from './VolumeAgeGroup'
import { VolumeDistribution } from './VolumeDistribution'
import { VolumeInsatsMix } from './VolumeInsatsMix'
import { VolumeProportionAgeGroup } from './VolumeProportionAgeGroup'
import { VolumeQualityMix } from './VolumeQualityMix'

export const benchmarkingVolume: ContentColumn[] = [
  {
    onlyRenderIfBenchmark: 'volume',
    content: (
      <VolumeDistribution
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Diagramen illustrerar fördelningen av brukare och kostnader mellan ordinärt boende och särskilt boende. 
            <br />
            <br />
            <b>Bakgrund och syfte</b>
            <br />
            Måttet beskriver om kommunen har en verksamhet som är tung mot insatser i ordinärt boende eller särskilt boende. Fördelningen spelar ofta stor roll för kostnadseffektivitet. Med väl 
            avvägda beslut om särskilt boende får kommunen hanterbara brukarvolymer på särskilt boende.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Över lag sker en utveckling där äldre i högre utsträckning önskar bo kvar hemma, och insatserna i ordinärt boende förbättras. En tyngdpunkt mot ordinärt boende 
            förutsätter ofta bättre fungerande hemtjänst och hemsjukvård som utgör basen i äldreomsorgen. En förskjutning mot högre andel i särskilt boende tenderar 
            att driva kostnaderna på totalen. Detta eftersom boendeplatser över hela vistelsetiden tenderar att kosta mer per brukare än hemtjänst.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Fördelning av volymer och kostnader inom äldreomsorgen',
  },
  {
    onlyRenderIfBenchmark: 'volumeAgeGroup',
    content: (
      <VolumeAgeGroup
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Andel med insatser från äldreomsorgen i respektive åldersgrupp',
  },

  {
    onlyRenderIfBenchmark: 'volumeProportionAgeGroup',
    content: (
      <VolumeProportionAgeGroup
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Fördelning mellan insatser i ordinärt resp. särskilt boende per åldersgrupp',
  },
  {
    onlyRenderIfBenchmark: 'volumeInsatsMix',
    content: (
      <VolumeInsatsMix
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Brukarvolym och insatsmix',
  },
  {
    onlyRenderIfBenchmark: 'volumeInsatsMix',
    content: (
      <HomeCareResidencyCostMix
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Insatsmix, kostnader',
  },
]
